import { useColorModeValue, Button, forwardRef, chakra, Tooltip, Box, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';

import getDefaultTransitionProps from 'theme/utils/getDefaultTransitionProps';
import IconSvg from 'ui/shared/IconSvg';

interface Props {
  isMobile?: boolean;
  isActive?: boolean;
  onClick?: () => void;
  className?: string;
  isCollapsed?: boolean;
}

const DownloadButton = ({ isMobile, isActive, onClick, className, isCollapsed }: Props, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const defaultIconColor = useColorModeValue('gray.600', 'gray.400');
  const bgColorMobile = useColorModeValue('blue.50', 'gray.800');
  const iconColorMobile = useColorModeValue('blue.700', 'blue.50');
  const buttonText = useColorModeValue('Download', 'Download');
  const buttonBgColor = useColorModeValue('blue.500', 'blue.300');
  const buttonHoverBgColor = useColorModeValue('blue.600', 'blue.400');
  const buttonTextColor = useColorModeValue('white', 'gray.800');

  const isExpanded = isCollapsed === false;
  const isXLScreen = useBreakpointValue({ base: false, xl: true });

  return (
    <Box
      as="li"
      listStyleType="none"
      w="100%"
      display="flex"
      justifyContent="center"
    >
      <Tooltip
        label={ buttonText }
        hasArrow={ false }
        isDisabled={ isMobile || isCollapsed === false || (isCollapsed === undefined && isXLScreen) }
        placement="right"
        variant="nav"
        gutter={ 20 }
        color={ isActive ? iconColorMobile : defaultIconColor }
        margin={ 0 }
      >
        <Button
          className={ className }
          variant="unstyled"
          display="inline-flex"
          alignItems="center"
          ref={ ref }
          h="48px"
          borderRadius="md"
          backgroundColor={ isActive ? bgColorMobile : buttonBgColor }
          _hover={{ backgroundColor: buttonHoverBgColor }}
          onClick={ onClick }
          aria-label="Download Jump"
          aria-roledescription="button"
          as="a"
          href="https://app.lianzhushou.com/1qKO"
          target="_blank"
          rel="noopener noreferrer"
          w={{ base: '100%', lg: isExpanded ? '100%' : '60px', xl: isCollapsed ? '60px' : '100%' }}
          px={{ base: 4, lg: isExpanded ? 4 : '15px', xl: isCollapsed ? '15px' : 4 }}
          whiteSpace="nowrap"
          color={ buttonTextColor }
          fontSize="md"
          fontWeight="bold"
        >
          <IconSvg
            name="wallet"
            width="36px"
            height="36px"
            padding="5px"
            color={ buttonTextColor }
            _hover={{ color: isMobile ? undefined : 'link_hovered' }}
            cursor="pointer"
            { ...getDefaultTransitionProps({ transitionProperty: 'margin' }) }
          />
          { isExpanded && (
            <span style={{ marginLeft: '12px' }}>{ buttonText }</span>
          ) }
        </Button>
      </Tooltip>
    </Box>
  );
};

export default chakra(forwardRef(DownloadButton));
